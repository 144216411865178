/**
 * Shorthand for expressing a max length error
 * @param {number} maxLength The max length applied
 * @returns {string} Helper error text
 */
function maxLengthError(maxLength: number) {
	return `Must be ${maxLength} characters or less`;
}

/**
 * Shorthand for expressing a max length error
 * @param {number} minLength The max length applied
 * @returns {string} Helper error text
 */
function minLengthError(minLength: number) {
	return `Must be ${minLength} characters or less`;
}

/**
 * Shorthand for expressing a required error
 * @returns {string} Helper error text
 */
function requiredError() {
	return "This is required";
}

/**
 * Shorthand for expressing a time type error
 * @returns {string} Helper error text
 */
function timeTypeError() {
	return "Must be a valid time";
}

/**
 * Shorthand for expressing a date type error
 * @returns {string} Helper error text
 */
function dateTypeError() {
	return "Must be a valid date";
}

/**
 * Shorthand for expressing a number type error
 * @returns {string} Helper error text
 */
function numberTypeError() {
	return "Must be a valid number"
}

function stringTypeError() {
	return "Must be a valid input";
}

function minNumberError(number: number) {
	return `Must be ${number} or greater`;
}

function maxNumberError(number: number) {
	return `Must be ${number} or less`;
}

function moreThanError(number: number) {
	return `Must be greater than ${number}`;
}

function lessThanError(number: number) {
	return `Must be less than ${number}`;
}

function emailError() {
	return 'Must be a valid email';
}

function phoneNumberError() {
	return 'Must be a valid phone number';
}

function minArrayError(number: number) {
	return `Must be at least ${number} items or greater`;
}

function maxArrayError(number: number) {
	return `Must be at least ${number} items or less`;
}

export {
	maxLengthError,
	minLengthError,
	requiredError,
	timeTypeError,
	dateTypeError,
	numberTypeError,
	stringTypeError,
	minNumberError,
	maxNumberError,
	emailError,
	phoneNumberError,
	moreThanError,
	lessThanError,
	minArrayError,
	maxArrayError
}