import {  BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import PayInvoice from "./screens/pay-invoice";
import { createTheme, CssBaseline, Theme, ThemeProvider } from "@mui/material";
import { SnackbarProvider } from "notistack";
import './App.css';
import { makeStyles } from "tss-react/mui";
import { styles } from "./utils/styles";
import waveBackground from './assets/WaveBackground.svg'

const axios = require('axios');
axios.defaults.baseURL = "https://portal.macgroupus.com/api";
// axios.defaults.baseURL = "http://localhost:8080";


const useStyles = makeStyles()(() => ({
	...styles,
	rootContainer: {
		...styles.column,
		flexGrow: 1,
		flexShrink: 1,
		flexBasis: "100%",
		maxWidth: "100%",
		maxHeight: "100%"
	},
	background:  {
		aspectRatio: "930/540",
		width: "100%",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center",
		backgroundSize: "cover",
		backgroundImage: `url(${waveBackground})`
	}
}))

function App() {
	const { classes, cx } = useStyles();
	const theme: Theme = createTheme({
		palette: {
			mode: 'light',
		},
		components: {
			MuiTextField: {
				defaultProps: {
					inputProps: {
						autoComplete: 'new-password'
					}
				},
				styleOverrides: {
					root: {
						marginBottom: 24,
						"& .MuiFormHelperText-root": {
							marginTop: 0,
							height: 0
						}
					}
				}
			},
			MuiCssBaseline: {
				styleOverrides: (_themeParam) => `
						::-webkit-scrollbar {
							width: 8px;
							background-color: #dfe6e9;
						},
						::-webkit-scrollbar-track {
							background-color: ${theme.palette.mode === "light" ? "#b2bec3" : "#ffffff"};
						},
						::-webkit-scrollbar-thumb {
							background-color: #969696;
							border-radius: 10px;
						}
					`
			}
		}
	});


	return (
		<ThemeProvider theme={theme}>
			<CssBaseline/>
			<SnackbarProvider
				autoHideDuration={3000}
				maxSnack={6}
				anchorOrigin={{
					horizontal: 'center',
					vertical: 'bottom'
				}}
				TransitionProps={{
					// @ts-ignore -- This seems wrong as the
					// direction prop is defined on Transition components
					direction: 'up'
				}}
			>
				<div className={cx(classes.rootContainer, classes.background)}>
					<BrowserRouter>
						<Routes>
							<Route path="payInvoice/:invoiceId" element={<PayInvoice/>}/>
							<Route path="*" element={<Navigate to='/payInvoice/invalid' replace={true}/>}/>
						</Routes>
					</BrowserRouter>
				</div>
			</SnackbarProvider>
		</ThemeProvider>
	);
}

export default App;
