import { Tooltip, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { styles } from "../utils/styles";
import { isBoolean, isNil, isString } from "remeda";
import { openInNewTab } from "../utils/general";
import * as React from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

const useStyles = makeStyles()((theme) => ({
	...styles,
	container: {
		// border: '1px solid #e0e0e0',
		borderRadius: 4,
		padding: '8.5px 10px 8.5px 12px',
		position: 'relative',
		marginBottom: 12
	},
	defaultHeight: {
		height: 40
	},
	wrappedHeight: {
		height: 'auto'
	},
	preventTextWrap: {
		whiteSpace: 'nowrap',
		textOverflow: 'ellipsis',
		overflow: 'hidden'
	},
	allowTextWrap: {
		whiteSpace: 'break-spaces'
	},
	label: {
		position: 'absolute',
		top: -10,
		left: 6,
		padding: '0 6px 0 6px',
		background: theme.palette.background.default
	},
	linkEnabled: {
		color: theme.palette.primary.main,
		cursor: 'pointer'
	}
}));

function LabeledData({
	label,
	data,
	addressModel,
	shouldWrap,
	dataAlign = 'left',
	className,
	onClick,
	toRouteOnClick,
	classes: userClasses
}: {
	label: string,
	data?: any,
	addressModel?: {
		name: string,
		name2: string,
		address: string,
		address2: string,
		city: string,
		state: string,
		zip: string,
		country: string
	} | null | undefined,
	shouldWrap?: boolean,
	dataAlign?: 'left' | 'center' | 'right',
	className?: string,
	onClick?: (e?: React.MouseEvent<HTMLSpanElement, MouseEvent>) => void,
	toRouteOnClick?: { to: string, options?: NavigateOptions, newTab?: boolean, enableWithNoData?: boolean },
	classes?: { label?: string, data?: string }
}) {
	const { cx, classes } = useStyles();
	const navigate = useNavigate();
	const containerRef = useRef(null as HTMLDivElement | null);
	const dataRef = useRef(null as HTMLSpanElement | null)
	const [isOverflowing, setIsOverflowing] = useState(false)

	const isAddress = !!addressModel;
	const isBool = isBoolean(data);
	const isStr = isString(data);
	const isNull = isNil(data);
	const isUndefined = data === undefined;
	const isEmpty = isStr && data.trim() === "";
	const noData = isNull || isUndefined || isEmpty;

	useEffect(() => {
		const localIsOverflowing = (containerRef.current && dataRef.current && dataRef.current?.getBoundingClientRect().width - 22.0 > containerRef.current?.getBoundingClientRect().width) || false;

		setIsOverflowing(localIsOverflowing)
	}, [containerRef.current, dataRef.current])

	return (
		// using an outer div here because flex doesn't handle growing/shrinking ideally when some children
		// have margin/padding and others don't because that gets factored into the growing/shrinking as a baseline
		// two options are: add same padding/margin to all children within a flex container or remove padding/margin
		// from all children in a flex container. We do the latter here by wrapping in a div with no padding/margin
		// problem further explained here: https://css-tricks.com/equal-columns-with-flexbox-its-more-complicated-than-you-might-think/
		<div className={className}>
			<div
				ref={(el) => containerRef.current = el}
				className={cx(classes.container, {
					[classes.defaultHeight]: !shouldWrap,
					[classes.wrappedHeight]: shouldWrap
				})}
			>
				<Typography
					className={cx(classes.label, userClasses?.label)}
					variant='caption'
				>
					{label}
				</Typography>
				{isAddress ?
					<>
						{addressModel.name && <Typography>{addressModel.name}</Typography>}
						{addressModel.name2 && <Typography>{addressModel.name2}</Typography>}
						{addressModel.address && <Typography>{addressModel.address}</Typography>}
						{addressModel.address2 && <Typography>{addressModel.address2}</Typography>}
						<div className={classes.row}>
							{addressModel.city && <Typography>{addressModel.city},&nbsp;</Typography>}
							{addressModel.state && <Typography>{addressModel.state},&nbsp;</Typography>}
							{addressModel.zip && <Typography>{addressModel.zip}</Typography>}
						</div>
						{addressModel.country && <Typography>{addressModel.country}</Typography>}
					</> :
					<Tooltip
						title={
						isOverflowing ?
							isBool ?
								data ?
									'Yes' :
									'No' :
								data :
							''
						}
						arrow={true}
						placement='top'
					>
						<Typography
							className={cx(userClasses?.data, {
								[classes.preventTextWrap]: !shouldWrap,
								[classes.allowTextWrap]: shouldWrap,
								[classes.linkEnabled]: !!onClick || (!!toRouteOnClick && (toRouteOnClick.enableWithNoData || !noData))
							})}
							sx={{
								textAlign: dataAlign
							}}
							variant='body1'
							onClick={(e) => {
								if (onClick) {
									onClick(e);
								}

								if (toRouteOnClick && (toRouteOnClick.enableWithNoData || !noData)) {
									if (toRouteOnClick.newTab) {
										openInNewTab(toRouteOnClick.to);
									} else {
										navigate(toRouteOnClick.to, toRouteOnClick.options);
									}
								}
							}}
						>
							<span
								ref={(el) => dataRef.current = el}
							>
								{isNull || isUndefined || isEmpty ?
									'\u00A0' :
									isBool ?
										(data ? "Yes" : "No") :
										data
								}
							</span>
						</Typography>
					</Tooltip>
				}
			</div>
		</div>
	);
}

export default LabeledData;