import { useEffect, useRef, useState } from "react";
import { makeStyles } from "tss-react/mui";
import { styles } from "../utils/styles";
import {
	Button,
	ButtonProps,
	Card,
	CardContent,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	IconButton,
	styled,
	Tooltip,
	Typography,
	useMediaQuery
} from "@mui/material";
import yup from "../utils/yup-extended";
import loginLogo from '../assets/Logo.svg';
import Spacer from "../components/spacer";
import LabeledData from "../components/labeled-data";
import FormCardInput from "../components/form-card-input";
import useSmartForm from "../utils/smart-form";
import { useParams } from "react-router-dom";
import FormTextField from "../components/form-text-field";
import { SubmitType } from "@Harris-Barrick-Software/use-form/dist/index.types";
import { InferType } from "yup";
import { maxLengthError, requiredError } from "../utils/yup-validation-helpers";
import { CheckCircle as CheckCircleIcon, Edit as EditIcon } from "@mui/icons-material";
import axios from "axios";
import { useSnackbar } from "notistack";
import { formatCurrency } from "../utils/helpers";

type CustomerBillingDetails = {
	name: string,
	name2: string,
	address: string,
	address2: string,
	city: string,
	state: string,
	zipCode: string,
}

type LinkResponse = {
	succeeded: boolean
	message: string,
	data?: {
		shipName: string,
		shipName2: string,
		shipAddress: string,
		shipAddress2: string,
		shipCity: string,
		shipState: string,
		shipZip: string,
		shipPhone: string,
		total: number
		orderType: string,
	}
}

const useStyles = makeStyles()(() => ({
	...styles,
	logo: {
		maxWidth: 350
	},
	card: {
		width: 600
	},
	containerPadding: {
		padding: 8
	},
	addMargin: {
		marginLeft: 4,
		marginRight: 4,
	},
	logoPadding: {
		paddingRight: 8,
		paddingLeft: 8
	},
}));

const validateCard = require('card-validator');

// @ts-ignore
const invoiceSchema = yup.object().shape({
	cardNumber: yup.string().test('cardNumber', "Card number invalid", (value) => {
		return validateCard.number(value).isValid;
	}),
	cardExpiration: yup.string().test('cardExpiration', "Card expiration invalid", (value) => {
		return validateCard.expirationDate(value).isValid;
	}),
	cardCvc: yup.string().when('cardNumber', (number, schema) => {
		return schema.test('check', "Card CVC invalid", (cvc: any) => {
			const cardInfo = validateCard.number(number);
			const isAmex = cardInfo?.card?.type === "american-express";
			return validateCard.cvv(cvc, isAmex ? 4 : 3).isValid;
		});
	}),
	customerBillingDetails: yup.object().required()
});

const billingSchema = yup.object().shape({
	name: yup.string().required(requiredError()).max(25, maxLengthError(25)),
	name2: yup.string().max(50, maxLengthError(50)),
	address: yup.string().required(requiredError()).max(50, maxLengthError(50)),
	address2: yup.string().max(50, maxLengthError(50)),
	city: yup.string().required(requiredError()).max(50, maxLengthError(50)),
	state: yup.string().required('Required').max(2).min(2),
	zipCode: yup.string().required(requiredError()).max(12, maxLengthError(12)),
});

const CustomButton = styled(Button)<ButtonProps>(({ theme }) => ({
	color: theme.palette.getContrastText("#246DFF"),
	backgroundColor: "#246DFF",
}));

function CustomerBilling({
	billingInfo,
	shippingInfo,
	onSave,
	onClose,
}: { billingInfo: CustomerBillingDetails | null, shippingInfo: Exclude<LinkResponse['data'], undefined> | null, onSave: Function, onClose: Function }) {
	const [isOpen, setIsOpen] = useState(true);

	const { cx, classes } = useStyles();
	const isLarger = useMediaQuery('(min-width:610px)');

	const { control, handleSubmit } = useSmartForm({
		schema: billingSchema,
		defaultValues: {
			name: billingInfo?.name || '',
			name2: billingInfo?.name2 || '',
			address: billingInfo?.address || '',
			address2: billingInfo?.address2 || '',
			city: billingInfo?.city || '',
			state: billingInfo?.state || '',
			zipCode: billingInfo?.zipCode || '',
		}
	});

	function saveBillingInfo(data: SubmitType<typeof control> & InferType<typeof billingSchema>) {
		//for consistency
		const capData = {
			name: data.name.toUpperCase(),
			name2: data.name2.toUpperCase(),
			address: data.address.toUpperCase(),
			address2: data.address2.toUpperCase(),
			city: data.city.toUpperCase(),
			state: data.state.toUpperCase(),
			zipCode: data.zipCode,
		}
		onSave(capData);
		setIsOpen(false);
	}

	return (
		<Dialog TransitionProps={{ onExited: () => onClose() }} open={isOpen} fullWidth={true} maxWidth="sm">
			<DialogTitle>
				<div className={cx(classes.row, classes.alignCenter)}>
					<img
						className={cx({
							[classes.logo]: true,
							[classes.logoPadding]: !isLarger
						})}
						src={loginLogo}
						alt='Logo'
					/>
				</div>
			</DialogTitle>
			<Typography variant='h5'
									align="center">{shippingInfo?.orderType} Total: {formatCurrency(shippingInfo?.total)}</Typography>
			<Spacer vertical={true}/>
			<Typography variant='h5' align='center'>
				Billing Information
			</Typography>
			<Spacer vertical={true}/>
			<Divider/>
			<DialogContent className={cx(classes.column)}>
				<Spacer vertical={true}/>
				<FormTextField
					formProps={{
						name: 'name',
						control
					}}
					label="Full Name"
					required={true}
					autoFocus={true}
				/>
				<FormTextField
					formProps={{
						name: 'name2',
						control
					}}
					label="Name 2"
				/>
				<FormTextField
					formProps={{
						name: 'address',
						control
					}}
					label="Address"
					required={true}
				/>
				<FormTextField
					formProps={{
						name: 'address2',
						control
					}}
					label="Address 2"
				/>
				<div className={cx({
					[classes.row]: true,
					[classes.flexWrap]: !isLarger
				})}>
					<FormTextField
						formProps={{
							name: 'city',
							control
						}}
						className={cx({
							'flex60': isLarger,
							'flex100': !isLarger
						})}
						label="City"
						required={true}
					/>
					{isLarger && <Spacer/>}
					<FormTextField
						formProps={{
							name: 'state',
							control
						}}
						className={cx({
							'flex15': isLarger,
							'flex100': !isLarger
						})}
						label="State"
						required={true}
						hideHelperText={true}
					/>
					{isLarger && <Spacer/>}
					<FormTextField
						formProps={{
							name: 'zipCode',
							control
						}}
						className={cx({
							'flex25': isLarger,
							'flex100': !isLarger
						})}
						label="Zip"
						required={true}
					/>
				</div>
			</DialogContent>
			<DialogActions className={cx(classes.row, classes.alignSpaceBetweenCenter)}>
				<Typography variant="body2" style={{ marginRight: 'auto' }}>&nbsp; * denotes required field</Typography>
				<CustomButton
					variant="contained"
					onClick={handleSubmit(saveBillingInfo)}
				>
					Next
				</CustomButton>
			</DialogActions>
		</Dialog>
	);
}

function PayInvoice() {
	const { cx, classes } = useStyles();
	const isLarger = useMediaQuery('(min-width:610px)');
	const { invoiceId } = useParams();
	const { enqueueSnackbar } = useSnackbar();

	const previousInvoiceId = useRef('');
	const [isLoading, setIsLoading] = useState(true);
	const [isInvalid, setIsInvalid] = useState(false);
	const [isManagingAddress, setIsManagingAddress] = useState(false);
	const [shippingAddress, setShippingAddress] = useState(null as null | Exclude<LinkResponse['data'], undefined>);
	const [isProcessing, setIsProcessing] = useState(false);
	const [succeeded, setSucceeded] = useState(false);

	const { control, watch, setValue, formState: { dirtyFields, isValid }, handleSubmit, reset } = useSmartForm({
		schema: invoiceSchema,
		defaultValues: {
			cardNumber: '',
			cardExpiration: '',
			cardCvc: '',
			customerBillingDetails: null as CustomerBillingDetails | null
		}
	});

	useEffect(() => {
		if (invoiceId !== previousInvoiceId.current) {
			previousInvoiceId.current = invoiceId || '';
			setIsLoading(true);
			setShippingAddress(null);
			setIsManagingAddress(false);

			(async () => {
				if (invoiceId && invoiceId !== "invalid") {
					const response = await axios.get<LinkResponse>(`/paymentLink`, {
						params: {
							link: invoiceId
						}
					});

					if (response.data.succeeded) {
						setShippingAddress(response.data.data!);
						setIsInvalid(false);
						setIsManagingAddress(true);
					} else {
						enqueueSnackbar(response.data.message, {
							variant: 'error'
						});

						setIsInvalid(true);
					}

				} else {
					setIsInvalid(true);
				}

				setIsLoading(false);
			})();
		}
	}, [invoiceId]);

	const watchedVars = {
		customerBillingDetails: watch('customerBillingDetails')
	}

	const isDisabled = !(dirtyFields.cardCvc && dirtyFields.cardExpiration && dirtyFields.cardNumber && isValid && watchedVars.customerBillingDetails);

	async function sendPayment(data: SubmitType<typeof control> & InferType<typeof invoiceSchema>) {
		setIsProcessing(true);
		const params = {
			link: invoiceId,
			cardInfo: {
				number: data.cardNumber.replace(/\D/g, ''),
				expiration: `20${data.cardExpiration.substring(5)}-${data.cardExpiration.substring(0, 2)}`,
				cvc: data.cardCvc,
			},
			customerBillingAddress: {
				name: data.customerBillingDetails!.name,
				name2: data.customerBillingDetails!.name2,
				address: data.customerBillingDetails!.address,
				address2: data.customerBillingDetails!.address2,
				city: data.customerBillingDetails!.city,
				state: data.customerBillingDetails!.state,
				zip: data.customerBillingDetails!.zipCode,
			}
		}

		try {
			let response = await axios.post<{ succeeded: boolean, message: string }>('/payPaymentLink', params);
			if (response.data.succeeded) {
				reset({}, { keepDefaultValues: true });
				setShippingAddress(null);
				setIsProcessing(false);
				setSucceeded(true);
			} else {
				setIsProcessing(false);
				enqueueSnackbar(response.data.message, {
					variant: 'error'
				});
			}
		} catch (error) {
			setIsProcessing(false);
			enqueueSnackbar("An error occurred.", {
				variant: 'error'
			});
		}
	}

	return (
		<>
			<div className={cx(classes.row, classes.alignCenterCenter, classes.containerPadding, 'flex')}>
				<Card
					className={cx({
						[classes.card]: isLarger,
						'flex': !isLarger,
						[classes.addMargin]: !isLarger
					})}
					raised={true}
				>
					<CardContent className={cx(classes.column)}>
						<div className={cx(classes.row, classes.alignCenter)}>
							<img
								className={cx({
									[classes.logo]: true,
									[classes.logoPadding]: !isLarger
								})}
								src={loginLogo}
								alt='Logo'
							/>
						</div>
						<Spacer vertical={true} height={24}/>
						<div className={cx(classes.row, classes.alignCenterCenter)}>
							<Typography variant='h5' align='center'>
								{isLoading ?
									"Loading Invoice for Payment" :
									isProcessing ? "Processing Payment" :
										succeeded ? "Payment Successful" :
											isInvalid ?
												"This is not a valid payment link" :
												"Pay Invoice"
								}
							</Typography>
							<Spacer/>
							{succeeded && <CheckCircleIcon style={{ color: "#33CC33" }} fontSize="large"/>}
						</div>
						<Spacer vertical={true}/>
						<Divider/>
						<Spacer vertical={true}/>
						{(isLoading || isProcessing) ?
							<div className={cx(classes.column, classes.alignCenterCenter)}>
								<CircularProgress/>
							</div> :
							(isInvalid || succeeded) ?
								<></> :
								<>
									<div className={cx(classes.row, classes.flexWrap)} style={{ position: 'relative' }}>
										<div className={cx({
											'flex': isLarger,
											'flex100': !isLarger,
											[classes.row]: true
										})}>
											<LabeledData
												label='Billing Address'
												addressModel={{
													name: watchedVars.customerBillingDetails?.name || "",
													name2: watchedVars.customerBillingDetails?.name2 || "",
													address: watchedVars.customerBillingDetails?.address || "",
													address2: watchedVars.customerBillingDetails?.address2 || "",
													city: watchedVars.customerBillingDetails?.city || "",
													state: watchedVars.customerBillingDetails?.state || "",
													zip: watchedVars.customerBillingDetails?.zipCode || "",
													country: ""
												}}
												shouldWrap={true}
											/>
											<IconButton
												size='small'
												style={{
													left: 95,
													color: "#246DFF",
													top: -20,
													position: 'absolute',
													padding: 8
												}}
												onClick={() => setIsManagingAddress(true)}
											>
												<Tooltip
													title="Edit Billing Address"
													placement="right"
												>
													<EditIcon fontSize="small"/>
												</Tooltip>
											</IconButton>
										</div>
										<LabeledData
											className={cx({
												'flex': isLarger,
												'flex100': !isLarger
											})}
											label='Shipping Address'
											addressModel={{
												name: shippingAddress!.shipName.toUpperCase(),
												name2: shippingAddress!.shipName2.toUpperCase(),
												address: shippingAddress!.shipAddress.toUpperCase(),
												address2: shippingAddress!.shipAddress2.toUpperCase(),
												city: shippingAddress!.shipCity.toUpperCase(),
												state: shippingAddress!.shipState.toUpperCase(),
												zip: shippingAddress!.shipZip,
												country: ""
											}}
											shouldWrap={true}
										/>
									</div>
									<FormCardInput
										numberFormProps={{
											name: 'cardNumber',
											control
										}}
										dateFormProps={{
											name: 'cardExpiration',
											control
										}}
										cvcFormProps={{
											name: 'cardCvc',
											control
										}}
									/>
									<Typography variant='h5' align="center">Total: {formatCurrency(shippingAddress?.total)}</Typography>
									<Spacer vertical={true} height={16}/>
									<div className={classes.row}>
										<CustomButton
											variant="contained"
											className="flex"
											size="large"
											disabled={isDisabled}
											onClick={() => handleSubmit(sendPayment)()}
										>
											Pay Now
										</CustomButton>
									</div>
								</>
						}
					</CardContent>
				</Card>
				{isManagingAddress &&
					<CustomerBilling
						onClose={() => {
							setIsManagingAddress(false);
						}}
						onSave={(data: CustomerBillingDetails) => {
							setValue('customerBillingDetails', data);
						}}
						billingInfo={watchedVars.customerBillingDetails}
						shippingInfo={shippingAddress}
					/>
				}
			</div>
		</>
	);
}

export default PayInvoice