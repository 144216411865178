import * as yup from "yup";
import { AnyObject, Maybe } from "yup/lib/types";
import BaseSchema from "yup/lib/schema";

yup.addMethod(yup.string, 'integer', function (this: yup.StringSchema) {
	return this.matches(/^(\d+|\s{0})$/, 'Must be numeric')
})

declare module 'yup' {
	interface StringSchema<TType extends Maybe<string> = string | undefined,
		TContext extends AnyObject = AnyObject,
		TOut extends TType = TType> extends BaseSchema<TType, TContext, TOut> {
		integer(): StringSchema<TType, TContext, TOut>
	}
}

export default yup;