function Spacer({
									width,
									height,
									vertical,
									doubleDefault
								}: { width?: number, height?: number, vertical?: boolean, doubleDefault?: boolean }) {
	return (
		<span style={{
			display: height || vertical ? 'block' : 'inline-block',
			width: width ? width : 12 * (doubleDefault ? 2 : 1),
			height: height ? height : 12 * (doubleDefault ? 2 : 1)
		}}/>
	);
}

export default Spacer;