import { useForm, yupResolver } from "@Harris-Barrick-Software/use-form";
import * as yup from "yup";


function useSmartForm<ValuesMap extends object>({
												schema,
												defaultValues,
												disabledDateCoercionFields,
												profile
											}: {
	schema: yup.ObjectSchema<any>,
	defaultValues: ValuesMap,
	disabledDateCoercionFields?: { [key: string]: boolean },
	profile?: boolean
}) {
	if (!schema) {
		throw new Error("A schema must be provided to useSmartForm");
	}

	return useForm({
		mode: "all",
		criteriaMode: "all",
		resolver: yupResolver(schema),
		defaultValues: defaultValues,
		disabledDateCoercionFields: disabledDateCoercionFields,
		profile: profile
	});
}

export default useSmartForm;
