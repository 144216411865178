export function formatCurrency(val: number | null | undefined, returnNull?: boolean) {
	let ret: string | null = null;
	if (!val) {
		if (returnNull) {
			ret = null;
		} else {
			ret = (0).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
		}
	} else {
		ret = val.toLocaleString('en-US', { style: 'currency', currency: 'USD' });
	}
	return ret === '-$0.00' ? '$0.00' : ret;
}

