import { useMemo } from 'react';
import { useLocation } from "react-router-dom";
import objectPath from "lodash";

function type(type: any) {
	return function (arg: any) {
		return typeof arg === type;
	};
}

/**
 * Return a comparator function
 * @param  {String} property The key to sort by
 * @param  {Function} map Function to apply to each property
 * @return {Function}        Returns the comparator function
 */
function sort(property: string, map?: Function) {
	let sortOrder = 1;
	let apply = map || function (_: any, value: any) {
		return value
	};

	if (property[0] === "-") {
		sortOrder = -1;
		property = property.substring(1);
	}

	return function fn(a: any, b: any) {
		let result: number = 0;
		let am = apply(property, objectPath.get(a, property));
		let bm = apply(property, objectPath.get(b, property));
		if (am < bm) result = -1;
		if (am > bm) result = 1;
		return result * sortOrder;
	}
}

/**
 * Return a comparator function that sorts by multiple keys
 * @return {Function} Returns the comparator function
 */
function sortBy() {
	let args = Array.prototype.slice.call(arguments);
	let properties = args.filter(type('string'));
	let map = args.filter(type('function'))[0];

	return function fn(obj1: any, obj2: any) {
		let numberOfProperties = properties.length,
			result = 0,
			i = 0;

		/* try getting a different result from 0 (equal)
		 * as long as we have extra properties to compare
		 */
		while (result === 0 && i < numberOfProperties) {
			result = sort(properties[i], map)(obj1, obj2);
			i++;
		}
		return result;
	};
}

function sortInPlace<T>(property: string, arr: T[]) {
	return arr.sort(sort(property));
}

function getToolbarElement() {
	return document.getElementById("app-bar-title");
}

function timeout(ms: number): Promise<NodeJS.Timeout> {
	return new Promise(resolve => setTimeout(resolve, ms));
}

function uuidv4(): string {
	return (`${1e7}${-1e3}${-4e3}${-8e3}${-1e11}`).replace(/[018]/g, c =>
		(parseInt(c) ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (parseInt(c) / 4)))).toString(16)
	);
}

function assert(condition: boolean, message: string) {
	if (!condition) {
		throw new Error(message || "Assertion failed");
	}
}

function useQuery(): URLSearchParams {
	const { search } = useLocation();

	return useMemo<URLSearchParams>(() => new URLSearchParams(search), [search]);
}

function openInNewTab(route: string) {
	const fullUrl = `${window.location.origin}${route}`;
	window.open(fullUrl, '_blank');
}

export {
	sortBy,
	sortInPlace,
	uuidv4,
	getToolbarElement,
	timeout,
	useQuery,
	assert,
	openInNewTab
};
