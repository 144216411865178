import { TextField } from "@mui/material";
import { useController } from "@Harris-Barrick-Software/use-form";
import { FormTextFieldProps } from "./form-text-field.types";

function FormTextField({ formProps, hideHelperText, ...restProps }: FormTextFieldProps) {
	const controller = useController({
		name: formProps.name,
		control: formProps.control
	});

	if (!controller) {
		return <></>
	}
	const { field, fieldState } = controller;

	return (
		<TextField
			error={fieldState.invalid}
			helperText={!!hideHelperText ? null : fieldState.error?.message}
			size='small'
			{...restProps}
			inputRef={field.ref}
			onChange={(e) => {
				field.onChange(e.target.value);
				if (restProps.onChange) {
					restProps.onChange(e);
				}
			}}
			onBlur={(e) => {
				if (restProps.onBlur) {
					restProps.onBlur(e);
				}
				field.onBlur();
			}}
			value={field.value}
		/>
	);
}

export default FormTextField;
