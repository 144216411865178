/* eslint-disable react-hooks/rules-of-hooks */
import { PaymentInputsWrapper, usePaymentInputs } from "react-payment-inputs";
import images from 'react-payment-inputs/images';
import { css } from "styled-components";
import { useController } from "@Harris-Barrick-Software/use-form";
import { FormCardInputProps } from "./form-card-input.types";
import { ChangeEvent } from "react";

const validateCard = require('card-validator');

const wrapperStyles = {
	fieldWrapper: {
		base: css`
      flex: 1;
			margin-bottom: 16px;
			min-height: 78px;
		`,
		errored: css`
      box-shadow: unset;
      border-color: rgb(244, 67, 54);
		`,
	},
	inputWrapper: {
		base: css`
      box-shadow: unset;
      height: 56px;
		`,
		errored: css`
      box-shadow: unset;
      border-color: rgb(244, 67, 54);
		`,
		focused: css`
      border-color: transparent;
      outline: 2px solid #246DFF;
      box-shadow: unset;
		`
	},
	input: {
		base: css`
      width: 100%;
      color: rgba(0, 0, 0, 0.87);
      padding: 0;
      font-size: 1rem;
      font-family: WotfardRegular, Roboto, Helvetica, Arial, "Open Sans";
      font-weight: 400;
      line-height: 1;
		`,
		errored: {},
		cardNumber: {},
		expiryDate: {},
		cvc: {}
	},
	errorText: {
		base: css`
      color: rgb(244, 67, 54);
      margin-left: 14px;
		`
	}
};

function FormCardInput({ numberFormProps, dateFormProps, cvcFormProps }: FormCardInputProps) {
	const numberController = useController({
		name: numberFormProps.name,
		control: numberFormProps.control
	});
	const dateController = useController({
		name: dateFormProps.name,
		control: dateFormProps.control
	});
	const cvcController = useController({
		name: cvcFormProps.name,
		control: cvcFormProps.control
	});

	if (!numberController || !dateController || !cvcController) {
		return <></>;
	}

	const { field: numberField } = numberController;
	const { field: dateField } = dateController;
	const { field: cvcField } = cvcController;

	const {
		wrapperProps,
		getCardImageProps,
		getCardNumberProps,
		getExpiryDateProps,
		getCVCProps,
		meta
	} = usePaymentInputs({
		cardNumberValidator: ({ cardNumber }) => validateCard.number(cardNumber).isValid ? '' : "Card number invalid",
		expiryValidator: ({ expiryDate }) => validateCard.expirationDate(expiryDate).isValid ? '' : "Expiration date invalid",
		cvcValidator: ({ cvc }) => {
			return validateCard.cvv(cvc, meta?.cardType?.type === "amex" ? 4 : 3).isValid ? '' : "CVC invalid"
		},
	});

	return (
		<PaymentInputsWrapper
			{...wrapperProps}
			styles={wrapperStyles}
		>
			{/* @ts-ignore */}
			<svg {...getCardImageProps({ images })} />
			<input
				{...getCardNumberProps({
					onChange: (e: ChangeEvent<HTMLInputElement>) => {
						numberField.onChange(e.target.value)
					},
					onBlur: () => {
						numberField.onBlur();
					}
				})}
				style={{
					flex: 5
				}}
				value={numberField.value}
			/>
			<input
				{...getExpiryDateProps({
					onChange: (e: ChangeEvent<HTMLInputElement>) => {
						dateField.onChange(e.target.value)
					},
					onBlur: () => {
						dateField.onBlur();
					}
				})}
				style={{
					flex: 3
				}}
				value={dateField.value}
			/>
			<input
				{...getCVCProps({
					onChange: (e: ChangeEvent<HTMLInputElement>) => {
						cvcField.onChange(e.target.value)
					},
					onBlur: () => {
						cvcField.onBlur();
					}
				})}
				style={{
					flex: 2
				}}
				value={cvcField.value}
			/>
		</PaymentInputsWrapper>
	);
}

export default FormCardInput;